// NPM
import React from "react";

// Components
import NavbarDark from "../../../components/navbarDark/navbarDark";

// CSS
import "./dataSheets.css";

// dataSheets
import gc from "../../../dataSheets/GC.jpg";
import cuc01 from "../../../dataSheets/Cuc-01.jpg";
import sc from "../../../dataSheets/SC.jpg";
import tdl from "../../../dataSheets/TD-L.jpg";
import td30 from "../../../dataSheets/TD3.0.jpg";
import nalaSwitch from "../../../dataSheets/nalaSwitch.jpg";
import termiAir from "../../../dataSheets/termiAir.jpg";

// dataSheets icon
import gcIcon from "../../../dataSheets/icon/gc.jpg";
import cuc01Icon from "../../../dataSheets/icon/Cuc-01.jpg";
import scIcon from "../../../dataSheets/icon/sc.jpg";
import tdlIcon from "../../../dataSheets/icon/TD-L.jpg";
import td30Icon from "../../../dataSheets/icon/TD-3.0.jpg";
import nalaSwitchIcon from "../../../dataSheets/icon/nalaSwitch.jpg";
import termiAirIcon from "../../../dataSheets/icon/termiAir.jpg";

function DataSheets() {
  const dataSheets = [
    {
      icon: gcIcon,
      image: gc,
      modelID: "Gateway Controller",
    },
    {
      icon: scIcon,
      image: sc,
      modelID: "Smart Controller",
    },
    {
      icon: cuc01Icon,
      image: cuc01,
      modelID: "Curtain Control Module",
    },
    {
      icon: tdlIcon,
      image: tdl,
      modelID: "TermiDrive-Lite Series",
    },
    {
      icon: td30Icon,
      image: td30,
      modelID: "TermiDrive 3.0",
    },
    {
      icon: nalaSwitchIcon,
      image: nalaSwitch,
      modelID: "NALA Switch",
    },
    {
      icon: termiAirIcon,
      image: termiAir,
      modelID: "TermiAir",
    },
  ];

  return (
    <div className="dataSheets">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <div className="container table">
            <br />
            <div className="row">
              <div className="col">
                <h3>Data Sheets</h3>
              </div>
            </div>
            <br />
            <div className="row align-items-center">
              {dataSheets.map((dataSheet, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                      <button
                        type="button"
                        className="btn "
                        data-toggle="modal"
                        data-target={"#exampleModalCenter" + index}
                      >
                        <div className="card text-black">
                          <img
                            className="card-img-top"
                            style={{ width: "100%" }}
                            src={dataSheet.icon}
                            alt={dataSheet.modelID}
                          />

                          <div className="card-body">
                            <h5 className="card-title">{dataSheet.modelID}</h5>
                          </div>
                        </div>
                      </button>
                    </div>
                    {/* ------------------------------------------------------- */}
                    <div
                      className="modal fade"
                      id={"exampleModalCenter" + index}
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby={"exampleModalCenterTitle" + index}
                      aria-hidden="true"
                    >
                      <div
                        className="modal-dialog modal-dialog-centered"
                        role="document"
                      >
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id={dataSheet.modelID}>
                              {dataSheet.modelID}
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="container">
                              <div className="row">
                                <div className="col">
                                  <img
                                    style={{ width: "100%" }}
                                    src={dataSheet.image}
                                    alt={dataSheet.modelID}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <a
                                    className="btn custom-btn"
                                    href={dataSheet.image}
                                    download
                                  >
                                    Download...
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="row align-items-center">
              <div className="col">
                <a className="btn custom-btn" href="mailto:info@delightintl.com?subject=More About Data Sheet">
                  More Products...
                </a>
              </div>
            </div>
            <br />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default DataSheets;
