// NPM
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

// CSS
import './App.css';

// Pages
import LandingPage from "./pages/landingpage/landingpage";
  // Company
  import AboutUs from "./pages/company/aboutUs/aboutUs";
  import NewsEvents from "./pages/company/newsEvents/newsEvents";
  import JoinUs from "./pages/company/joinUs/joinUs";
  import ContectUs from "./pages/company/contactUs/contactUs";
  // Solutions
  import Lighting from "./pages/solutions/lighting/lighting";
  import HotelRoom from "./pages/solutions/hotelRoom/hotelRoom";
  // Projects
  import Projects from "./pages/projects/projects";
  // Supports & Services
  import AppsDownload from "./pages/supportsServices/appsDownload/appsDownload"
  import DataSheets from "./pages/supportsServices/dataSheets/dataSheets";

// Context
import { LanguageProvider } from './context/language';

function App() {
  return (
    <LanguageProvider>
      <div className="App">
        <Router>
          <Switch>
            {/* Company */}
            <Route exact path="/company/aboutUs" component={AboutUs}/>
            <Route exact path="/company/newsEvents" component={NewsEvents}/>            
            <Route exact path="/company/joinUs" component={JoinUs}/>
            <Route exact path="/company/contactUs" component={ContectUs}/>
            {/* Solutions */}
            <Route exact path="/solutions/lighting" component={Lighting}/>
            <Route exact path="/solutions/hotelRoom" component={HotelRoom}/>
            {/* Projects */}
            <Route exact path="/projects" component={Projects}/>
            {/* Supports & Services */}
            <Route exact path="/supportsServices/appsDownload" component={AppsDownload}/>
            <Route exact path="/supportsServices/dataSheets" component={DataSheets}/>
            {/* Home Page */}
            <Route exact path="/" component={LandingPage}/>
            {/* Redirect to landing page when no such path */}
            <Route render={() => <Redirect to="/"/>} />
          </Switch>
        </Router>
      </div>
    </LanguageProvider>
  );
}

export default App;