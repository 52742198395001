// NPM
import React from "react";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";

// CSS
import "./contactUs.css";

// Icon
import { Envelope, House, Telephone } from "react-bootstrap-icons";

function ContactUs() {
  return (
    <div className="contactUs">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <div className="container">
            <br />
            <div className="row">
              <div className="col">
                <h3>Contact Us</h3>
              </div>
            </div>
            <hr />
            <br />
            <div className="row">
              <div className="col">
                <h4 className="text-left">
                  地址 Address{" "}
                  <span>
                    <House />
                  </span>
                </h4>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                <strong>
                  香港研发总部 Hong Kong Research &amp; Development Headquarter:
                </strong>
                <p style={{ marginBottom: "0px" }}>
                  Unit 808, Wing On Plaza, 62 Mody Road, TST, Hong Kong{"\n"}
                </p>
                <p>香港，尖沙咀，么地道62号，永安中心，808室</p>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                <strong>
                  深圳研发中心 Shenzhen Product Development Center:
                </strong>
                <p style={{ marginBottom: "0px" }}>
                  Unit 213, Building 6W, Youth Innovation &amp; Entrepreneur
                  Hub, Nanshan Districe, Shenzhen, PRC
                </p>
                <p>深圳市，南山区，前海深港青年梦工场，6W栋，213室</p>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <h4 className="text-left">
                  Email <Envelope />
                </h4>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                <a href="mailto:info@delightintl.com?subject=Contact Us">
                  <ins>info@delightintl.com</ins>
                </a>
              </div>
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <h4 className="text-left">
                  Phone <Telephone />
                </h4>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-left">
                <a href="tel:+85227800277">+852 2780 0277</a>
              </div>
            </div>
            <br />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default ContactUs;
