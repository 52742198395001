// NPM
import React from "react";

// Component
import Navbar from "../../components/navbar/navbar";

// CSS
import "./landingpage.css";

function LandingPage() {
  return (
    <div className="landingpage">
      <div className="bg-cover">
        <React.Fragment>
          <Navbar />
          {/* Main Body */}
          <div className="row">
            <h1 className="col" id="text">
              We Enable Everyone To Control Energy
            </h1>
          </div>
          <div id="custom-button-bar" className="row justify-content-md-center">
            <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12">
              <a href="/solutions/lighting" className="custom-btn btn">
                Lighting
              </a>
            </div>
            <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12">
              <a href="/solutions/hotelRoom" className="custom-btn btn">
                Hotel Room
              </a>
            </div>
            <div className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12">
              <a href="https://www.psn-evc.com/" className="custom-btn btn">
                EV CHARGING
              </a>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default LandingPage;
