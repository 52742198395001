// NPM
import React, { useState } from "react";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";
import Video from "./video";

// CSS
import "./lighting.css";

// Videos
import lightingVideoChi from "../../../video/lightingVideoChi.mp4";
import lightingVideoEng from "../../../video/lightingVideoEng.mp4";

// Images
import webControl1 from "../../../image/lighting/webControl1.jpg";
import webControl2 from "../../../image/lighting/webControl2.jpg";

function Lighting() {
  const [language, setLanguage] = useState(false);
  const onClick = () => {
    setLanguage(!language);
  };

  const contents = [
    {
      title: "Multi-level Fault Tolerance",
      words:
        "Local edge computing with control even without cloud and data network",
    },
    {
      title: "Safe for human touch",
      words:
        "The system is running in Direct Current with low voltage, anyone can play around with it without electric shocking risk",
    },
    {
      title: "No Flicker",
      words: "No flicker can protect eyesight, allow better lighting effect.",
    },
    {
      title: "No E-cap, No Carcinogen",
      words: "Using E-cap can cause a risk of getting cancer",
    },
    {
      title: "Lower Installation Cost",
      words: "It has a lower installation, operation and maintenance cost.",
    },
    {
      title: "Long Last Life Cycle",
      words: "Operation duration can last 4 times the normal system.",
    },
    {
      title: "Easy to Install",
      words: "All you need is just unplug and plug",
    },
  ];

  const webDemos = [
    webControl1,
    webControl2
  ];

  return (
    <div className="lightingpage">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <br />
          <div className="container text-left eye-catching table">
            <br />
            <div className="row">
              <div className="col">
                <h3 className="text-center">NALA Lighting System</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm">
                <div className="container">
                  {contents.map((content, index) => {
                    return (
                      <div key={index}>
                        <div className="row">
                          <div className="col">
                            <h3>{content.title}</h3>
                            <p>{content.words}</p>
                          </div>
                        </div>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-sm">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      {language ? (
                        <Video videoSrc={lightingVideoChi} />
                      ) : (
                        <Video videoSrc={lightingVideoEng} />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center">
                      <button onClick={onClick} className="custom-btn">
                        中文 / ENGLISH
                      </button>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="text-center">Web Control</h3>
              </div>
            </div>
            <br/>
            {webDemos.map((webDemo, index) => {
              return (
                <React.Fragment key={index}>
                  <div className="row">
                    <div className="col">
                      <img style={{width:"100%"}} src={webDemo} alt={"webDemo" + index} />
                    </div>
                  </div>
                  <br/>
                </React.Fragment>
              );
            })}
          </div>
          <br/>
        </React.Fragment>
      </div>
    </div>
  );
}

export default Lighting;
