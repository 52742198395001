// NPM
import React from "react";

// CSS
import "./projects.css";

// Component
import NavbarDark from "../../components/navbarDark/navbarDark";

// Video
import videoSrc from "../../video/NALA_ApplicationRefForWeb.mp4";

function Projects() {
  return (
    <div className="projects">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <br />
          <div className="container table">
            <div className="row">
              <div className="col">
                <br />
                <h3>Projects</h3>
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <video
                  className="table"
                  src={videoSrc}
                  style={{ width: "100%" }}
                  type="video/mp4"
                  controls
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <a
                  className="btn custom-btn"
                  style={{ borderColor: "black" }}
                  href="mailto:info@delightintl.com?subject=More Projects"
                >
                  <p>For More...</p>
                </a>
              </div>
            </div>
            <br />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default Projects;
