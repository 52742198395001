import React from 'react';
import ReactDOM from 'react-dom';

// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import HttpApi from "i18next-http-backend";

import './index.css';
import App from './App';
import { DataProvider } from "./context/dataContext";

/* 
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "zh"],
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "htmlTag"],
      caches: ["localStorage"]
    },
    backend: {
      loadPath: "/assets/languages/{{lng}}.json",
    },
    react: {useSuspense: false}
  });
*/

ReactDOM.render(
  <React.StrictMode>
    <DataProvider>
      <App />
    </DataProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// reportWebVitals();