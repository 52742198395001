// NPM
import React from "react";

// CSS
import "./appsDownload.css";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";

function AppsDownload() {
  return (
    <div className="appsDownload">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <div className="container">
            <div className="row">
              <div className="col">
                <br />
                <h3>Download the Latest NALA App</h3>
                <a
                  className="btn custom-btn"
                  href="https://open.delightintl.com/app/download?from=singlemessage&amp;isappinstalled=0"
                >
                  Download...
                </a>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default AppsDownload;
