// NPM
import React from "react";

// CSS
import "./hotelRoom.css";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";

// Image
import image from "../../../image/hotelRoom/tabletImage.png";
import romanticQRCode from "../../../image/hotelRoom/officeQRCode.png";

function HotelRoom() {
  const contents = [
    "One time setting, accompany world wide",
    "All Lighting Control",
    "Single Device Control Light Scene",
    "All in One Control",
    "Air Condition Control",
    "Curtain Control",
    "Ventilation Control",
  ];

  return (
    <div className="hotelRoom">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <br />
          <div className="container table eye-catching">
            <br />
            <div className="row">
              <div className="col">
                <h3>Hotel Room</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-sm">
                <div className="container">
                  <ul>
                    {contents.map((content, index) => {
                      return (
                        <div key={index}>
                          <br />
                          <div className="row">
                            <div className="col">
                              <li>
                                <h5 className="text-left">{content}</h5>
                              </li>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                  <br/>
                  <div className="row">
                    <div className="col">
                      <img className="table" style={{width:"50%"}} alt="romanticQRCode" src={romanticQRCode}/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <h5>Romantic Scene Control QR Code</h5>
                    </div>
                  </div>
                  <br/>
                </div>
              </div>
              <div className="col-sm">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <img
                        className="table"
                        alt="tabletControl"
                        src={image}
                        style={{ width: "80%" }}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <h5>Hotel Room Control Panel</h5>
                  </div>
                </div>
                <br/>
              </div>
            </div>
          </div>
          <br/>
        </React.Fragment>
      </div>
    </div>
  );
}

export default HotelRoom;
