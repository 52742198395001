// NPM
import React from "react";

function Video(props) {
    return (
        <React.Fragment>
            <video key={props.videoSrc} style={{width:"100%"}} controls>
                <source src={props.videoSrc} type="video/mp4" />
            </video>
        </React.Fragment>
    )
};

export default Video;