// NPM
import React, { useContext } from "react";
import { List } from "react-bootstrap-icons";

// CSS
import "./navbarDark.css";

// Language
import { LanguageContext } from "../../context/language";

function NavbarDark() {
  const { dictionary } = useContext(LanguageContext);

  return (
      <React.Fragment>
          <nav className="navbar navbar-expand-md custom-nav">
            <a href="/">
              <img src={process.env.PUBLIC_URL + "/logo_dppl_noBackground.png"} width="auto" height="60px" className="d-inline-block align-top" alt="CompanyLogo"/>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"  aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span><List color="black"/></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="nav navbar-nav mx-auto">
                <li className="nav-item custom-nav-btn">
                  <a className="nav-link navDark" href="/">{dictionary.navbar.home}</a>
                </li>

                <li className="nav-item dropdown custom-nav-btn">
                  <a className="nav-link navDark dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown">
                    {dictionary.navbar.company.company}
                  </a>
                  <div className="dropdown-menu custom-dropdown-menu">
                    <a className="dropdown-item custom-dropdown-item" href="/company/aboutUs">{dictionary.navbar.company.aboutUs}</a>
                    <a className="dropdown-item custom-dropdown-item" href="/company/newsEvents">{dictionary.navbar.company.newsEvents}</a>
                    <a className="dropdown-item custom-dropdown-item" href="/company/joinUs">{dictionary.navbar.company.joinUs}</a>
                    <a className="dropdown-item custom-dropdown-item" href="/company/contactUs">{dictionary.navbar.company.contactUs}</a>
                  </div>
                </li>

                <li className="nav-item dropdown custom-nav-btn">
                  <a className="nav-link navDark dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown">
                    {dictionary.navbar.solutions.solutions}
                  </a>
                  <div className="dropdown-menu custom-dropdown-menu">
                    <a className="dropdown-item custom-dropdown-item" href="/solutions/lighting">{dictionary.navbar.solutions.lighting}</a>
                    <a className="dropdown-item custom-dropdown-item" href="/solutions/hotelRoom">{dictionary.navbar.solutions.hotelRoom}</a>
                    <a className="dropdown-item custom-dropdown-item" href="https://www.psn-evc.com/">{dictionary.navbar.solutions.evc}</a>
                  </div>
                </li>

                <li className="nav-item custom-nav-btn">
                  <a className="nav-link navDark" href="/projects">
                  {dictionary.navbar.projects.projects}
                  </a>
                </li>

                <li className="nav-item dropdown custom-nav-btn">
                  <a className="nav-link navDark dropdown-toggle" href="/" id="navbarDropdownMenuLink" data-toggle="dropdown">
                    {dictionary.navbar.supportsServices.supportsServices}
                  </a>
                  <div className="dropdown-menu custom-dropdown-menu">
                    <a className="dropdown-item custom-dropdown-item" href="/supportsServices/appsDownload">{dictionary.navbar.supportsServices.appsDownload}</a>
                    <a className="dropdown-item custom-dropdown-item" href="/supportsServices/dataSheets">{dictionary.navbar.supportsServices.dataSheets}</a>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
      </React.Fragment>
  )
}

export default NavbarDark;