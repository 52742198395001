// NPM
import React from "react";

// CSS
import "./joinUs.css";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";
import { Person } from "react-bootstrap-icons";

function JoinUs() {
  const packs = [
    {
      role: "Web / Mobile App Developer",
      id: "webAppDeveloper",
      jobHighlights: [
        "Web app, mobile app (Android and iOS)",
        "Fresh graduates, part-time are welcomed",
        "IoT, smart energy control system"
      ],
      jobResponsibilities: [
        "Design and develop mobile apps and website to fulfil business’s need",
        "Conduct system development, coding and design including documentation",
        "Provide daily support to maintain the system up and running smoothly to support business daily operation"
      ],
      jobRequirements: [
        "Computer Science, IT or related discipline",
        "Experience in the whole development cycle of Android and iOS mobile app",
        "Experience in development of web application with database management",
        "Experience in design e-commence platforms and webpage will be an advantage",
        "Proficiency in HTML5/ JavaScript / PHP / C programming",
        "Knowledge in SQL, replicated database",
        "Enjoy learning new technology",
        "Self-motivated, able to work under pressure, team player",
        "Strong sense of responsibility",
        "Fresh graduate, part-time will also be considered",
        "More experience will be considered as senior position"
      ],
      applyFor: "Application for Web / Mobile App Developer",
    },
    {
      role: "Electronic Engineer",
      id: "electronicEngineer",
      jobHighlights: [
        "World-class & Award-win Smart Energy ControlSystem",
        "Electronic Engineering",
        "5-day work week, medical insurance",
      ],
      jobResponsibilities: [
        "Formulate the specification of circuits, schematics & PCB Layouts",
        "for 3rd party design house to work for the company",
        "Design (circuits, schematics & PCB Layouts), implement, test and improve hardware in product development",
        "Bill-of-material (BOM) generation, components selection/validation",
        "To support external partners for project execution through successful completion on time"
      ],
      jobRequirements: [
        "Electronic Engineering or related disciplines are preferred",
        "2 years of experience in high technology /  electronics / lighting industry",
        "Experience with PCB design and ability to layout the circuit",
        "Familiar with EDA tools such as Altium Designer or Cadence",
        "Ability to work independently with minimal supervision",
        "Candidates with less experience will be considered for the post of Assistant Engineer"
      ],
      applyFor: "Application for Electronic Engineer",
    },
    {
      role: "Application Engineer",
      id: "applicationEngineer",
      jobHighlights: [
        "smart building/ software / electronics industry",
        "Electrical/Electronic Engineering",
        "Innovative Technology Company",
      ],
      jobResponsibilities: [
        "Actively involved in research, diagnose, trouble shoot and identify solutions to resolve technical issues with the support from our competence centre",
        "To deliver training on solutions and provide product support to channel partners",
        "To support internal and external project/product application engineering for productization and realisation",
        "To provide key functions for product management and customer services",
        "To structure and prepare compelling technical documentation for internal and external clients",
        "To support system integrators for project execution through successful completion on time, within defined scope and time",
      ],
      jobRequirements: [
        "Electrical/Electronic Engineering or related disciplines are preferred",
        "2 years of experience in high technology / software / electronics / lighting industry preferred",
        "Experienced in working with company like AV / E&M system integrator / contractor / consulting and distributor preferred",
        "Hands-on Microsoft Office including Visio, Access will have definite advantage",
        "Self-motivated, committed, possess good analytical and interpersonal skills",
        "Travel to the Shenzhen office and R&D facility may require",
        "Fresh graduates will also be considered",
        "Candidates with less experience will be considered for the post of Assistant Engineer",
      ],
      applyFor: "Application for Application Engineer",
    },
    {
      role: "Firmware Engineer",
      id: "firmwareEngineer",
      jobHighlights: [
        "Firmware develop in RT-OS / Embedded Linux system",
        "Linux, C/C++, Python development",
        "Innovative Technology Company"
      ],
      jobResponsibilities: [
        "Analyze technical requirements, develop and build firmware",
        "Work on communication protocol",
        "Work on embedded software, in lighting and control products",
        "Write and review engineering design, code and documentation",
        "Work closely with the Apps and Cloud developers to support the design and development of new functions/features as well as complete new product ranges",
        "Define agile development process, and help deliver firmware packages on schedule",
        "Maintain, optimize and debug existing software components",
        "Contribute to the back-end implementation and architecture design of the system",
        "Follow disciplined software development practice to create maintainable software"
      ],
      jobRequirements: [
        "Degree in Electronic Engineering, Computer Science, Software Engineering, or related discipline",
        "Minimum 2 years of firmware programming experience in embedded systems (C/C++)",
        "Strong skillset in C/C++ programming, and debugging",
        "Familiar with communication protocol, UDP/TCP/IP/MQTT , etc…",
        "Familiar with hardware interface like GPIO, PWM, I2C, I2S, UART, SPI etc...",
        "Familiar with embedded OS (freeRTOS, embedded linux etc.), including memory management, system call, process scheduling, IPC, etc...",
        "Understand software quality assurance, testing and validation methodology",
        "Autonomous, methodical, and solution focused",
        "Good verbal and written communication skills (English, Mandarin is a big plus)"
      ],
      applyFor: "Application for Firmware Engineer",
    },
    {
      role: "Technician 技術員 / 技工",
      id: "technician",
      jobHighlights: [
        "5天工作制、銀行假期及有薪年假",
        "團體醫療保險及項目花紅",
        "先進及創新科技"
      ],
      jobResponsibilities: [
        "測試及安裝電子設備, 照明, 電車充電及智能系統",
        "Responsible to maintenance and repair services, including ELV projects and control room",
        "Testing and installation of ELV, Sensor and Lighting Control system",
        "Participate in new technology and solution development"
      ],
      jobRequirements: [
        "持有電力A牌者優先",
        "懂Ms Word / Excel 一般操作",
        "能夠閱讀簡單英文電器圖紙"
      ],
      applyFor: "應徵技術員 / 技工",
    },
  ];

  return (
    <div className="joinUs">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <div className="container table">
            <br />
            <div className="row">
              <div className="col">
                <h3>Join Us</h3>
              </div>
            </div>
            <br />
            {/* New Role */}
            <div className="row">
              <div className="col">
                <h4 className="text-md-left">We are now hiring:</h4>
              </div>
            </div>
            <hr width="80%" />
            <div id="accordion">
            {/* ------------------------------------------------------------ */}
              {packs.map((pack, index) => {
                return (
                  <div key={index} className="card">
                    <div className="card-header" id={pack.id}>
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link custom-toggle-btn"
                          data-toggle="collapse"
                          data-target={"#collapse" + index}
                          aria-expanded="true"
                          aria-controls={"collapse" + index}
                        >
                          <h5>
                            <Person /> {pack.role}
                          </h5>
                        </button>
                      </h5>
                    </div>
                    <div
                      id={"collapse" + index}
                      className="collapse"
                      aria-labelledby={pack.id}
                      data-parent="#accordion"
                    >
                      <br />
                      <h5>Job Highlights</h5>
                      <div className="card-body">
                        <div className="row text-left">
                          <div className="col">
                            {pack.jobHighlights.map((jobHighlight, index) => {
                              return <li key={index}>{jobHighlight}</li>;
                            })}
                          </div>
                        </div>
                        <br />
                        <h5>Job Description</h5>
                        <div className="row text-left">
                          <div className="col">
                            <br />
                            <h5>Responsibilities:</h5>
                            {pack.jobResponsibilities.map(
                              (jobResponsibility, index) => {
                                return <li key={index}>{jobResponsibility}</li>;
                              }
                            )}
                          </div>
                        </div>
                        <div className="row text-left">
                          <div className="col">
                            <br />
                            <h5>Requirements:</h5>
                            {pack.jobRequirements.map(
                              (jobRequirement, index) => {
                                return <li key={index}>{jobRequirement}</li>;
                              }
                            )}
                          </div>
                        </div>
                        <br/>
                        <div className="row text-left">
                          <div className="col">
                            <p>
                              Tailored training and career development will be
                              provided to help the candidate to growth with the
                              company. If you have the desire for a challenging
                              and rewarding career development, send us your
                              resume quoting the reference number, your present
                              and expected salary by clicking “APPLY NOW” or
                              email to the Human Resources Department.
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <a className="btn custom-btn" href={"mailto:info@delightintl.com?subject=" + pack.applyFor}>
                              Apply Now...
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* ------------------------------------------------------------ */}

            </div>
            <br />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default JoinUs;
