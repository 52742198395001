// NPM
import React from "react";

// CSS
import "./newsEvents.css";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";

// Icon
import { Clock } from "react-bootstrap-icons";

// Images (Hardcoded!!! To Be Update!!!)
import image1 from "../../../image/news/20160329.png";
import image2 from "../../../image/news/20160408.png";
import image3 from "../../../image/news/20160515.png";
import image4 from "../../../image/news/20170224.jpg";
import image5 from "../../../image/news/20181114.jpg";
import image6 from "../../../image/news/20201211.jpg";
import image7 from "../../../image/news/20210305.png";
import image8 from "../../../image/news/20211207.jpg";

function NewsEvents() {
  const newsList = [
    {
      image: image8,
      newsDescribe: "SGBC Member Glorybene Smart System Pte Ltd worked with Republic Polytechnic on a DPPL smart lighting project to showcase real-world examples and industry best practices.",
      date: "07 Dec, 2021",
      url: "https://www.sgbc.sg/images/Glorybene_RP.pdf",
    },
    {
      image: image7,
      newsDescribe:
        "Is 'shadow charging' the answer to the many challenges faced by existing EV charging stations?",
      date: "05 Mar, 2021",
      url: "https://e27.co/is-shadow-charging-the-answer-to-the-many-challenges-faced-by-existing-ev-charging-stations-20210305/",
    },
    {
      image: image6,
      newsDescribe:
        "DeLight Power participated in 2020 Dream Big innovation technology competition",
      date: "11 Dec, 2020",
      url: "",
    },
    {
      image: image5,
      newsDescribe:
        "IET Sustainability and Environmental Impact Award 2018 goes to DPPL",
      date: "14 Nov, 2018",
      url: "",
    },
    {
      image: image4,
      newsDescribe:
        "DeLight Power participated in 2017 First@SciencePark Exhibition (23-24 Feb)",
      date: "24 Feb, 2017",
      url: "",
    },
    {
      image: image3,
      newsDescribe:
        "DeLight Power participated in Build4Asia Conference 2016 to promote sustainable development in building services",
      date: "15 May, 2016",
      url: "",
    },
    {
      image: image2,
      newsDescribe:
        "DeLight Power participated in EYAcademy CSR Challenge 2016 to nurture next generation in community engagement and entrepreneurship",
      date: "8 April 2016",
      url: "",
    },
    {
      image: image1,
      newsDescribe:
        "DeLight Power participated in HKIE END Project Competition 2016",
      date: "29 March 2016",
      url: "",
    },
  ];

  return (
    <div className="newsEvents">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <br />
          <div className="container">
            <div className="table">
              <br />
              <div className="row">
                <div className="col">
                  <h3>News &amp; Events</h3>
                </div>
              </div>
              <hr width="80%" />
              {/* Hardcode News To Be Updated */}
              {newsList.map((news, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          style={{ width: "200px", paddingBottom: "10px" }}
                          alt={news.date}
                          src={news.image}
                        />
                      </div>
                      <div className="col-md-8  text-md-left text-center">
                        <div className="row" style={{ paddingBottom: "10px" }}>
                          <div className="col">
                            <h4>{news.newsDescribe}</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <p style={{ fontSize: "10px" }}>
                              <Clock /> {news.date}
                            </p>
                          </div>
                        </div>
                        {news.url ? (
                          <div className="row">
                            <div className="col">
                              <a
                                className="btn custom-btn"
                                href={news.url}
                              >
                                Continue Reading...
                              </a>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <br />
                    <hr width="80%" />
                    <br />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <br />
        </React.Fragment>
      </div>
    </div>
  );
}

export default NewsEvents;
