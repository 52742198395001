// NPM
import React from "react";

// CSS
import "./aboutUs.css";

// Component
import NavbarDark from "../../../components/navbarDark/navbarDark";

// image
import image from "../../../image/henry.jpg";

function AboutUs() {
  return (
    <div className="aboutUs">
      <div className="bg-cover">
        <React.Fragment>
          <NavbarDark />
          <div className="container">
            <br />
            <div className="row">
              <div className="col">
                <h3>About DeLight</h3>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <h5 className="text-left">
                  DeLight Power Products Limited (DPPL)
                </h5>
              </div>
            </div>
            <br />
            <div className="row text-left">
              <div className="col">
                <h5>Our Vision</h5>
                <p>To be a Global Player in Smart Energy Control System</p>
              </div>
            </div>
            <br />
            <div className="row text-left">
              <div className="col">
                <h5>Our Mission</h5>
                <p>
                  To Deliver Affordable Healthy, Intelligent &amp; Green Energy
                  Control Solutions for Everyone
                </p>
              </div>
            </div>
            <br />
            <div className="row text-left">
              <div className="col">
                <p>
                  We specialize to develop, manufacture &amp; market cleanest
                  Energy Control Solution{" "}
                </p>
                <p>
                  We designed our system to be energy efficient, environmentally
                  friendly, easy to use and maintain, long living and at
                  affordable costs. Our product range is compatible with most
                  devices in the market. We were recognized in HK and
                  internationally:
                </p>
                <ul>
                  <li>2015 APAC Global Challenge Siemens Award</li>
                  <li>Hong Kong Green Building Council Award</li>
                  <li>IE Business School Best Startup Award</li>
                </ul>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <h5 className="text-left">Founder</h5>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-8">
                <p className="text-left">
                  Henry Yau is the Chief Executive Officer and founder for
                  DeLight Power Products Limited, transformational technology to
                  change the future of energy control with key benefits of
                  environmental-friendly, cost and energy savings. Previously,
                  Henry was Chief Financial Officer of Tri-con, Philips &amp;
                  YaMing Lighting, and Getz Brothers and held senior management
                  positions in different companies across Hong Kong, China,
                  Taiwan and United States. He created one of the top three
                  fastest supercomputer in China and received many patents
                  including a patent for non-electrolytic capacitor power
                  supply. Henry holds B.S. / M.S. of Electrical Engineering –
                  Computer Science, J.D. of Law, MBA as well as DBA.{" "}
                </p>
              </div>
              <br />
              <div className="col-md-4">
                <img alt="founder pic" src={image} style={{ width: "150px" }} />
                <h4 style={{ paddingTop: "10px" }}>Henry Yau</h4>
              </div>
            </div>
            <br />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}

export default AboutUs;
